<template>
  <unity
    src="/clp/Build/clp.json"
    unity-loader="/clp/Build/UnityLoader.js"
  />
</template>

<script>
  import Unity from 'vue-unity-webgl'

  export default {
    name: 'KDADemoCLP',

    components: { Unity },
  }
</script>
